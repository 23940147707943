import React, { useState, useRef } from "react";
import {
  Typography,
  Box,
  Container,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { CardWhy } from "./Shared/CardWhy";

const cardsData = [
  {
    title: "את רוצה להכנס להריון",
    baseText:
      "אחרי כשלונות חוזרים, הגיע הזמן להפסיק לעשות את אותו הדבר ולצפות לתוצאות שונות. ",
  },
  {
    title: "מדויק לגוף שלך",
    baseText:
      "אין פרוטוקול סטנדרטי שמתאים לכולן. בסקירה תקבלי המלצות מותאמות למצב הרפואי שלך, בליווי הסבר מפורט לגבי היתרונות והחסרונות בכל תרופה, שילוב תרופות, טיפול או תוסף תזונה.",
  },
  {
    title: "רפואה מתקדמת",
    baseText:
      "תיחשפי לטיפולים חדשניים ופורצי דרך להתמודדות עם כשל שחלתי מוקדם, רירית דקה, הפלות חוזרות, כשלונות השרשה חוזרים, איכות ירודה של ביציות ועוברים, אנדומטריטיס ועוד מגוון של מחלות ופתולוגיות של מערכת הרבייה. ",
  },
  {
    title: "תגלי ממה כדאי לך להימנע",
    baseText:
      "כמו שחשוב שתביני מה האופציות העומדות לרשותך כך גם חשוב להבין מה עלול לפגוע בסיכויים שלך להרות. לעיתים, דווקא הורמונים ו-ויטמינים שיש לגביהם הסכמה רחבה עלולים להחמיר את המצב בקרב נשים עם מחלות רקע שונות.לדוגמא: non-classical CAH (תסמונת אשר בלא מעט מקרים מאובחנת בטעות כתסמונת השחלות הפוליציסטיות).",
  },
  {
    title: "רפואה טבעית",
    baseText:
      "לפעמים הפתרון הנכון ביותר הוא דווקא הפתרון הטבעי ביותר. זה נכון תמיד, אבל בטיפולי פוריות זה אפילו חשוב עוד יותר. בסקירה, לצד פתרונות מעולם הרפואה הקונבנציונלית, יושם דגש גם על פתרונות טבעיים ואלטרנטיביים במידת האפשר.  ",
  },
  {
    title: "קבלת תמונה מלאה על הגוף שלך",
    baseText:
      "לרוב הרופאים אין את הזמן לעבור על ההיסטוריה הרפואית המלאה שלך. המצב הזה יוצר פער בין האידיאל לבין הטיפול הקיים. גישה הוליסטית, שהיא אבן בסיס בסקירה הרפואית, היא קריטית להצלחה של טיפולי הפוריות- מאחר ומערכות הגוף השונות שלנו עובדות יחד ובסנכרון כדי לאפשר ביוץ של ביצית, הפריה שלה ע״י הזרע, התפתחות עוברית מוקדמת, השרשה של העובר בדופן הרחם ותמיכה והזנה שלו לאורך ההריון כולו",
  },
];

export const WhyUs: React.FC = () => {
  return (
    <Container maxWidth="md" style={{ direction: "rtl", marginTop: 30 }}>
      <Typography
        variant="h4"
        align="right"
        sx={{
          marginBottom: 1,
          color: "#123c42",
          fontWeight: "bold",
          marginRight: 5,
        }}
      >
        למה סקירה רפואית מותאמת אישית ?
      </Typography>
      {cardsData.map((card) => {
        return <CardWhy title={card.title} base={card.baseText} />;
      })}
    </Container>
  );
};
