import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel styles
import "./carouselStyles.css"; // Add your custom styles here
import { sentences } from "./sentences";
import { Typography } from "@mui/material";

const ImageCarousel = () => {
  return (
    <div className="carousel-container">
      <img
        src="https://fertility-supportcenter-videos.s3.eu-north-1.amazonaws.com/back4.png"
        className="bg-image"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "80%",
          objectFit: "cover",
          zIndex: 1, // Ensure the image is behind the content
          pointerEvents: "none", // Prevent the image from blocking interaction
        }}
      />
      <Carousel
        showArrows={true}
        autoPlay={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        interval={4000}
        stopOnHover={false}
        dynamicHeight={false}
        showIndicators={false}
      >
        {sentences.map((sentence) => {
          return (
            <Typography
              variant="h5"
              align="center" // Center the text horizontally
              sx={{
                width: "89%", // Set a smaller width for the text block
                maxWidth: "600px", // Optionally, limit the maximum width for larger screens
                marginLeft: "auto", // Center the text block horizontally
                marginRight: "auto", // Center the text block horizontally
                textAlign: "center", // Center the text within the block
                color: "#e55d8d",
                letterSpacing: "1px", // Add slight spacing between letters
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)", // Add subtle shadow for depth
                background: "linear-gradient(90deg, #e55d8d, #ff6b6b)", // Optional: Add a gradient to the text
                WebkitBackgroundClip: "text", // Clip the gradient to the text
                WebkitTextFillColor: "transparent", // Make the text transparent to show the gr
              }}
            >
              {sentence}
            </Typography>
          );
        })}
      </Carousel>
    </div>
  );
};

export default ImageCarousel;
