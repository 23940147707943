import React, { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Typography,
  Button,
  Box,
  Container,
  Card,
  Dialog,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import "../fontRubik.css";
import ImageCarousel from "./ImageCarousel";
import CloseIcon from "@mui/icons-material/Close";
import { Team } from "./Team";
import { ContactUsDialog } from "./Shared/ContactUsDialog";
import { WhatsAppButton } from "./Shared/WhatsAppButton";
import { WhyUs } from "./WhyUs";
import { GoodHands } from "./GoodHands";
import { WhatInclude } from "./WhatInclude";

const theme = createTheme({
  typography: {
    fontFamily: "'Arimo', sans-serif",
    h3: {
      color: "#1c7d83",
    },
    h6: {
      fontFamily: "'Arimo', sans-serif", // Keep consistent typography
      fontSize: "1.1rem", // Increase font size slightly for more prominence
      fontWeight: 300, // Make the font slightly bolder for emphasis
      letterSpacing: "0.05rem", // Add letter spacing for elegance
      lineHeight: 1.5, // Improve readability with more line height
      textShadow: "1px 1px 4px rgba(0, 0, 0, 0.15)", // Subtle shadow for depth
    },
    h5: {
      fontFamily: "'Rubik', sans-serif",
    },
  },
  direction: "rtl", // Right to left layout
  palette: {
    primary: {
      main: "#23bcc9",
    },
    secondary: {
      main: "#23bcc9",
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#123c42",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "0.8rem",
          color: "#23bcc9",
        },
      },
    },
  },
});

const Home: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [openPrices, setOpenPrices] = useState(false);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // Handle opening the dialog
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleOpenPrice = () => {
    setOpenPrices(true);
  };

  // Handle closing the dialog
  const handleClose = () => {
    setOpen(false);
  };

  const handleClosePrice = () => {
    setOpenPrices(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundColor: "#f0f4f8", // Set the background color here
          minHeight: "100vh", // Ensure it takes full height of the viewport
          paddingTop: "20px",
        }}
      >
        <ImageCarousel />

        <Container maxWidth="md" style={{ direction: "rtl" }}>
          {/* <Card
            sx={{
              maxWidth: 1000,
              margin: "20px auto",
              borderRadius: "16px",
              overflow: "hidden",
              // boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)",
              // padding: "40px", // Add some padding to create space around the image
            }}
          > */}
          <Box
            //  justifyContent="center"
            display="flex"
            flexDirection={"row"} // Stacks vertically on small screens, horizontal on larger
            alignItems="flex-end"
            sx={{ marginBottom: 3 }}
          >
            <img
              src="https://fertility-supportcenter-videos.s3.eu-north-1.amazonaws.com/logo.png"
              alt="Diagram"
              style={{
                maxWidth: "100px",
                height: "auto",
              }}
            />
            {/* Title on the left */}
            <Typography
              variant="h4"
              //   align="left" // Align text to the left
              sx={{
                marginBottom: 1,
                color: "#123c42",
                fontWeight: "bold",
                marginTop: 3,
                marginRight: { md: "20px", xs: 0 }, // Add margin only on larger screens
                paddingBottom: 1,
              }}
            >
              ליווי נשים עם קשיי פוריות
            </Typography>
          </Box>
          <Typography variant="h5" align="right" sx={{ marginBottom: 1 }}>
            טיפולי הפוריות צריכים להיות מותאמים לך באופן אישי, כמו כל תחום בעולם
            הרפואה. דיוק של פרוטוקול הטיפול יכול להוציא אותך מהלופ הכל כך קשה של
            עוד ועוד טיפולי פוריות שמסתיימים ללא הצלחה.
          </Typography>
          <Typography variant="h5" align="right" sx={{ marginBottom: 1 }}>
            המטרה שלנו היא לוודא שהטיפול שאת מקבלת הוא הטיפול הכי מדויק והכי
            נכון עבורך. לכוון אותך , לפזר את הערפל ולתת לך את הכלים תוך ליווי עם
            ייעוץ והכוונה. בעזרת יצירת סקירת פוריות רפואית מותאמת אישית.
          </Typography>
          <Typography
            variant="h5"
            //   align="left" // Align text to the left
            sx={{
              marginBottom: 1,
              color: "#123c42",
              fontWeight: "bold",
              marginTop: 3,
              paddingBottom: 1,
            }}
          >
            למה עלייך לצפות?
          </Typography>
          <Typography variant="h5" align="right" sx={{ marginBottom: 1 }}>
            קודם כל, אנחנו ניצור איתך קשר ונוודא התאמה. הליווי הוא למטופלות אשר
            חוות קושי ארוך טווח בלבד בכניסה להריון מאחר ולכל סקירה רפואית
            מוקדשות שעות רבות של מחקר על מנת שתהייה מעמיקה ואיכותית.
          </Typography>
          <Typography variant="h5" align="right" sx={{ marginBottom: 1 }}>
            במידה ותמצאי מתאימה, נתאים לך את
            <span
              onClick={handleOpenPrice}
              style={{
                display: "inline",
                color: "#e55d8d",
                cursor: "pointer",
                textDecoration: "underline",
                marginRight: "6px",
              }}
            >
              תוכנית הליווי
            </span>{" "}
            אשר מתאימה לצרכייך. תקבע פגישת היכרות ראשונית במהלכה נכיר אותך
            לעומק. חשוב לנו באמת להכיר , להבין ולדעת מה עברת עד היום - גם בפן
            הרגשי וגם בפן הפיזי. תשתפי אותנו בקשי פוריות שחווית עד כה, נלמד על
            ההרגלים ביום יומיים שלך ונמלא ביחד שאלון רפואי דיסקרטי.
          </Typography>
          <Typography variant="h5" align="right" sx={{ marginBottom: 1 }}>
            על סמך הפגישה הראשונית, הצוות הרפואי ייקבע אילו בדיקות רפואיות
            נדרשות ממך על מנת ליצור את הסקירה הרפואית. נעביר אלייך רשימה מסודרת
            של בדיקות ומידע רפואי על מנת שנוכל להתקדם
          </Typography>
          <Typography variant="h5" align="right" sx={{ marginBottom: 1 }}>
            עם קבלת כל המסמכים הרפואיים הרלוונטים, הצוות שלנו יוכל להתחיל להכין
            עבורך סקירה רפואית מותאמת אישית. במהלכה נעבור על כל ההיסטוריה
            הרפואית שלך ונכיר כל בדיקה חריגה. נעבור על כל הבדיקות מטיפולי
            הפוריות שביצעת בעבר ומהם נלמד איך לשפר את התגובה השחלתית והרחמית
            שלך. נלמד את הרגלי התזונה שלך, את אורח החיים שלך, את הצרכים ואת
            הרצונות שלך.
          </Typography>

          <Typography variant="h5" align="right" sx={{ marginBottom: 1 }}>
            מתוך המקום ההוליסטי הזה, ועם הידע וכלי המחקר שברשותנו, נדע להכווין
            אותך בצורה הכי טובה שניתן בדרכך להיות אמא. טיפולי פוריות הם מסע
            דינמי ואנחנו נהיה לצידך לכל אורכו, עם תמיכה רגשית ומענה מדעי,
            מקצועי, זמין ונגיש לכל שאלה או צורך.
          </Typography>
          <Typography
            variant="h5"
            align="right"
            sx={{ marginTop: 5, fontWeight: "bold" }}
          >
            זה הזמן שלך לקחת אחריות על העתיד שלך ואנחנו כאן כדי ללוות אותך בדרך
            הזאת.
          </Typography>
          {/* </Card> */}
          <Box
            sx={{
              position: "relative",
              paddingBottom: "56.25%", // Keep the aspect ratio as is
              height: 0,
              overflow: "hidden",
              borderRadius: "10px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              width: "100vw", // Make the container full-width
              maxWidth: "100%", // Ensure it doesn’t overflow the screen
              margin: "0 auto", // Center it horizontally if needed
            }}
          >
            <video
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                borderRadius: "10px",
              }}
              controls
              autoPlay
              muted
              loop
              poster="path/to/poster-image.jpg"
            >
              <source
                src="https://fertility-supportcenter-videos.s3.eu-north-1.amazonaws.com/encoded_Video2.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </Box>
          <Box display="flex" justifyContent="center" sx={{ marginBottom: 5 }}>
            <Button
              onClick={handleClickOpen}
              aria-label="צרי קשר" // Directly provides the label for accessibility
            >
              <img
                src="https://fertility-supportcenter-videos.s3.eu-north-1.amazonaws.com/contactus.png"
                alt="Contact Us Logo"
                style={{
                  height: 70,
                  marginRight: 30,
                  marginBottom: 10,
                  marginTop: 5,
                }}
              />
            </Button>
            <WhatsAppButton />
          </Box>
          <Box display="flex" justifyContent="center" sx={{ marginBottom: 5 }}>
            <Button
              aria-labelledby="button-description"
              onClick={handleOpenPrice}
              aria-label="לחץ פה לקבלת פרטים לחבילות ומחירים"
            >
              <img
                src="https://fertility-supportcenter-videos.s3.eu-north-1.amazonaws.com/pricesButton.png"
                alt="Logo"
                style={{
                  height: 70,
                  marginRight: 30,
                  marginBottom: 10,
                  marginTop: 5,
                }}
              />
            </Button>
          </Box>

          {/* Dialog with the form */}
          <Dialog
            open={openPrices}
            onClose={handleClosePrice}
            fullWidth
            sx={{ direction: "rtl" }}
            PaperProps={{
              sx: {
                //  width: isSmallScreen ? '90%' : '50%', // 90% width on small screens, 50% on large
                maxWidth: isSmallScreen ? "lg" : "md", // Remove maxWidth restriction on small screens
                //  margin: 'auto', // Center the dialog
              },
            }}
          >
            <DialogTitle>
              {/* Close button aligned to the right */}
              <IconButton
                aria-label="close"
                onClick={handleClosePrice}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            {/* Image below Typography */}
            <img
              src="https://fertility-supportcenter-videos.s3.eu-north-1.amazonaws.com/prices6.png"
              alt="Diagram"
              style={{
                maxWidth: "100%",
                height: "auto",
                width: "100%", // Ensure the image fits within the dialog width
              }}
            />
          </Dialog>
          <ContactUsDialog open={open} onClose={handleClose} />

          <Divider
            style={{
              margin: "10px 0",
              backgroundColor: "black",
              height: 1,
            }}
          />
          <WhatInclude />
          <Divider
            style={{
              margin: "100px 0",
              backgroundColor: "black",
              height: 1,
            }}
          />
          {/* <Box
            display="flex"
            justifyContent="center"
            sx={{
              maxHeight: {
                xs: 950, // 800px on small screens
                sm: 1300,
                md: 1500, // 1500px on medium to large screens
              },
              overflow: "hidden", // Hide the bottom part of the image
            }}
          ></Box> */}
          <WhyUs />

          <Team />
          <GoodHands />
          <Divider
            style={{
              marginTop: 40,
              backgroundColor: "black",
              height: 1,
            }}
          />
        </Container>

        <Box display="flex" justifyContent="center">
          <img
            src="https://fertility-supportcenter-videos.s3.eu-north-1.amazonaws.com/prices6.png"
            alt="Diagram"
            style={{
              maxWidth: "100%",
              height: "auto",
              marginTop: "40px",
            }}
          />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Home;
