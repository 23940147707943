import React from "react";
import { Typography, Card, Box } from "@mui/material";

export const ItemInclude: React.FC<{
  title: string;
  base: string;
  img: string;
  num: string;
}> = (props) => {
  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        padding: "20px",
        //    backgroundColor: "#f3e3eb",
        // borderRadius: "16px",
        // boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)",
        // width: "800px",
        alignItems: "center",
        margin: "20px auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h2"
          align="right" // Align text to the right within the box
          sx={{
            marginBottom: 1,
            color: "#7ed2cf",
            fontWeight: "bold",
            fontFamily: "monospace",
            marginRight: 2,
          }}
        >
          {props.num}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginRight: 3,
          }}
        >
          {/* Stack the title and base vertically */}
          <Typography
            variant="h5"
            sx={{
              marginBottom: 1,
              color: "#123c42",
              fontWeight: "bold",
              marginTop: 5,
            }}
          >
            {props.title}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              marginBottom: 1,
              color: "#123c42",
              marginTop: 1,
            }}
          >
            {props.base}
          </Typography>
        </Box>
      </Box>
      <Box
        component="img"
        src={props.img}
        alt="Diagram"
        sx={{
          maxWidth: "150px",
          height: "50%",
          // marginRight: "20px", // Add margin between the image and the text
        }}
      />
      {/* </Box> */}
    </Card>
  );
};
